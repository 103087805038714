import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AOuQ1DYzjk from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_Eg4DPJPcY6 from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_1aypKNZ222 from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import autoAnimate_4HQGapz9xs from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/plugins/autoAnimate.ts";
import crisp_client_DCubnCdDP5 from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/plugins/crisp.client.ts";
import posthog_client_fUucxKWhZE from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/codebuild/output/src3990498539/src/DocuChatv2/web-app/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AOuQ1DYzjk,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  scrollbars_client_Eg4DPJPcY6,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  autoAnimate_4HQGapz9xs,
  crisp_client_DCubnCdDP5,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]